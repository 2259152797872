
<template>
   <div>
      <div class="flex items-center w-full p-4 bg-primary text-white top-header-page" @click.stop="back()">
         <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="back()"></feather-icon>
         <h4 class="text-white">Isi Data</h4>
      </div>

      <vx-card title-color="primary" title="Formulir data digital">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
               <vs-input label="Nama Pemilik Sertipikat" v-model="dataNama" class="w-full"  v-validate="'required'" name="Nama"/>
               <span class="text-danger text-sm" v-show="errors.has('Nama')">{{ errors.first('Nama') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Nomor Sertipikat" v-model="dataNomor" class="w-full" v-validate="'required'" name="Nomor"/>
               <span class="text-danger text-sm" v-show="errors.has('Nomor')">{{ errors.first('Nomor') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <small class="date-label">Umur</small>
               <vx-input-group>
                  <vs-input v-model="dataUmur" v-validate="'max:2|numeric|required'" name="Umur"/>
                  <template slot="append">
                     <div class="append-text bg-primary">
                     <span>Tahun</span>
                     </div>
                  </template>
               </vx-input-group>
               <span class="text-danger text-sm" v-show="errors.has('Umur')">{{ errors.first('Umur') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Pekerjaan" v-model="dataPekerjaan" class="w-full" v-validate="'required'" name="Pekerjaan"/>
               <span class="text-danger text-sm" v-show="errors.has('Pekerjaan')">{{ errors.first('Pekerjaan') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="No. KTP" v-model="dataKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="KTP"/>
               <span class="text-danger text-sm" v-show="errors.has('KTP')">{{ errors.first('KTP') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mt-4">
               <vs-textarea label="Alamat" v-model="dataAlamat" class="w-full" v-validate="'required'" name="Alamat"/>
               <span class="text-danger text-sm" v-show="errors.has('Alamat')">{{ errors.first('Alamat') }}</span>
            </div>
         </div>
      </vx-card>

      <div class="my-5 flex justify-end">
         <vs-switch v-model="dataKuasa">
            <span slot="on">Dikuasakan</span>
            <span slot="off">Tidak Dikuasakan</span>
         </vs-switch>
      </div>

      <vx-card title-color="primary" v-if="dataKuasa">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Nama" v-model="dataKuasaNama" class="w-full"  v-validate="'required'" name="Kuasa Nama"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Nama')">{{ errors.first('Kuasa Nama') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <small class="date-label">Umur</small>
               <vx-input-group>
                  <vs-input v-model="dataKuasaUmur" v-validate="'max:2|numeric|required'" name="Kuasa Umur"/>
                  <template slot="append">
                     <div class="append-text bg-primary">
                     <span>Tahun</span>
                     </div>
                  </template>
               </vx-input-group>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Umur')">{{ errors.first('Kuasa Umur') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Pekerjaan" v-model="dataKuasaPekerjaan" class="w-full" v-validate="'required'" name="Kuasa Pekerjaan"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Pekerjaan')">{{ errors.first('Kuasa Pekerjaan') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="No. KTP" v-model="dataKuasaKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="Kuasa KTP"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa KTP')">{{ errors.first('Kuasa KTP') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mt-4">
               <vs-textarea label="Alamat" v-model="dataKuasaAlamat" class="w-full" v-validate="'required'" name="Kuasa Alamat"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Alamat')">{{ errors.first('Kuasa Alamat') }}</span>
            </div>
         </div>
      </vx-card>
      
      <vx-card title-color="primary" title="Bidang tanah terletak">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-select
               label="Kecamatan"
               v-model="dataKecamatan"
               v-validate="'required'"
               class="w-full"
               @change="mountKelurahan()"
               @click.prevent="changeKecamatan()"
               >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
               </vs-select>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-select
               label="Kelurahan"
               v-model="dataKelurahan"
               v-validate="'required'"
               class="w-full"
               @change="pilihKelurahan()"
               @click.prevent="changeKelurahan()"
               >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKelurahan"/>
               </vs-select>
            </div>
         </div>
      </vx-card>
      
      <div class="flex">
         <vs-button color="success" type="relief" class="w-full mt-5 mx-auto" @click.stop="submitData()" :disabled="!isFormValid"> SUBMIT </vs-button>
      </div>
   </div>
</template>

<script>
   import { mapGetters } from 'vuex'
   export default {
      data () {
         return {
            dataNomor: '',
            dataNama: '',
            dataUmur: '',
            dataPekerjaan: '',
            dataKTP: '',
            dataAlamat: '',
            // Kuasa
            dataKuasa: false,
            dataKuasaNama: '',
            dataKuasaUmur: '',
            dataKuasaPekerjaan: '',
            dataKuasaKTP: '',
            dataKuasaAlamat: '',
            // Lokasi
            listKecamatan: [],
            dataKecamatan: 0,
            dataKecamatanNama: '',
            dataChangeKecamatan: false,
            listKelurahan: [],
            dataKelurahan: 0,
            dataKelurahanNama: '',
            dataChangeKelurahan: false,

            dataLayanan: null,
            dataData: [],
            
         }
      },
      components: {
      },
      computed: {
         ...mapGetters({
            user : 'auth/user',
         }),
         isFormValid () {
            let kuasa = true
            if (this.dataKuasa == true && 
               this.dataKuasaNama != '' &&
               this.dataKuasaUmur != '' &&
               this.dataKuasaPekerjaan != '' &&
               this.dataKuasaKTP != '' &&
               this.dataKuasaAlamat != '') {
                  kuasa = true
            } else if (this.dataKuasa == false) {
               kuasa = true
            } else {
               kuasa = false
            }
            return kuasa && 
            !this.errors.any() &&
            this.dataNomor != '' &&
            this.dataNama != '' &&
            this.dataUmur != '' &&
            this.dataPekerjaan != '' &&
            this.dataKTP != '' &&
            this.dataAlamat != ''
         }
      },
      created () {
         this.$store.commit('navbarUpdateStatus', true)
      },
      mounted () {
         /*
         if (this.$route.params.data.nama) {
            this.dataNama = this.$route.params.data.nama
            this.dataNomor = this.$route.params.data.nomor
         }
         */
         this.dataLayanan = this.$route.params.item
         this.mountKecamatan()
      },
      methods: {
         mountKecamatan () {
            let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
            this.axios.get('/term-list/kecamatan', configs)
            .then((response) => {
               let {data} = response.data
               this.listKecamatan = data
            })
            .catch((error) => {
               let data = error.data
               this.listKecamatan = data
            }) 
         },
         changeKecamatan () {
            this.dataChangeKecamatan = true
         },
         mountKelurahan () {
            if (this.dataChangeKecamatan) {
               const ItemIndex = this.listKecamatan.find((camat) => camat.id == this.dataKecamatan)
               this.dataKecamatanNama = ItemIndex.name
               let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
               this.axios.get('/term-parent/'+this.dataKecamatan, configs)
               .then((response) => {
                  let {data} = response.data
                  this.listKelurahan = data
               })
               .catch((error) => {
                  let data = error.data
                  this.listKelurahan = data
               }) 
            }
         },
         changeKelurahan () {
            this.dataChangeKelurahan = true
         },
         pilihKelurahan () {
            if (this.dataChangeKelurahan) {
               const ItemIndex = this.listKelurahan.find((lurah) => lurah.id == this.dataKelurahan)
               this.dataKelurahanNama = ItemIndex.name
            }
         },
         back () {
            let item = this.$route.params.item
            this.$router.replace({ 
               name: 'LayananItem',
               params: { item } 
            });
         },
         submitData () {
            this.$validator.validateAll().then(result => {
            if (result) {
               this.$vs.loading()
               let formData = new FormData()

               formData.set('title', this.dataNama+' - '+this.dataLayanan.task_title)
               formData.set('status', 'publish')
               formData.set('comment_status', 'open')
               formData.set('password', null)
               formData.set('parent', 0)
               formData.set('type', 'berkas')
               formData.set('comment_count', 0)

               let meta = {
                  nomor: this.dataNomor,
                  nama: this.dataNama,
                  umur: this.dataUmur,
                  pekerjaan: this.dataPekerjaan,
                  ktp: this.dataKTP,
                  alamat: this.dataAlamat,
                  // Kuasa
                  kuasa : this.dataKuasa,
                  kuasa_nama : this.dataKuasaNama,
                  kuasa_umur : this.dataKuasaUmur,
                  kuasa_pekerjaan : this.dataKuasaPekerjaan,
                  kuasa_ktp : this.dataKuasaKTP,
                  kuasa_alamat : this.dataKuasaAlamat,
                  // Lokasi
                  kecamatan_id: this.dataKecamatan,
                  kecamatan_name: this.dataKecamatanNama,
                  kelurahan_id: this.dataKelurahan,
                  kelurahan_name: this.dataKelurahanNama, 

                  berkas : this.dataLayanan.id,
                  berkas_name : this.dataLayanan.task_title
               }
               formData.set('meta', JSON.stringify(meta))
               
               const ItemIndex = this.dataLayanan.relation.alur.find((alur) => alur.alur_order === '1')
               
               let relation =  {
                  'alur': [ItemIndex.taxonomy_id]
               }
               formData.set('relation', JSON.stringify(relation))

               formData.set('urutan', 'desc')
               this.$store.dispatch('taskStore', formData)
               .then((response) => {
                  this.$vs.loading.close()
                  this.$vs.notify({
                     title: 'Berhasil',
                     color  : response.status,
                     text  : 'Data telah kami terima, Silahkan tentukan jadwal kunjungan',
                  })
                  this.dataData = response.data.data
                  //this.toJadwal(response.data)
                  this.$router.push('/jadwal')
               })
               .catch(error => {
                  this.$vs.loading.close()
                  let {data} = error.response
                  this.$vs.notify({
                     title: 'Error',
                     text: data.message,
                     iconPack: 'feather',
                     icon: 'icon-alert-circle',
                     color: 'danger'
                  })
               })
            }
            })
         },
         toJadwal (submit) {
            let item = this.$route.params.item
            //let submit = data
            this.$router.push({
               name: "LayananJadwal",
               params: { item, submit }
            });
         }
      }
   }
</script>

<style scoped>
</style>
